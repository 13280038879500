import { Pagination, Switch, Table } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../../../store/action_types";
import EditQuest from "../EditQuest";
import {
  DeleteIcon,
  EditIcon,
  FolderIcon,
} from "../../../../SharedComponents/icons/icons";
import SearchBar from "../../../../SharedComponents/SearchBar";

const { confirm } = Modal;

const QuestListing = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [Daata, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch({
      type: types.QuestListingRequest,
      payload: {
        page: 1,
      },
    });

    dispatch({
      type: types.PrizePoolListingRequest,
      payload: {
        page: 1,
      },
    });
  }, []);

  const {
    questAllData,
    editQuestModalOpen,
    questDetailByIdData,
    prizePoolListingAllData,
  } = useSelector((state) => ({
    questAllData: state.QuestReducer.questListingAllData,
    editQuestModalOpen: state.QuestReducer?.editQuestModalOpen,
    questDetailByIdData: state.QuestReducer.questDetailByIdData,
    prizePoolListingAllData:
      state.PrizePoolReducer.prizePoolListingAllData?.result?.prizePool,
  }));

  const { searchTextQuest } = useSelector((state) => ({
    searchTextQuest: state.QuestReducer.searchText,
  }));

  const handleEditRecord = (item) => {
    dispatch({
      type: types.QuestDetailByIdRequest,
      payload: item?._id,
    });
  };

  // useEffect(() => {
  //   if (questAllData?.result?.quests?.docs) {
  //     setData(questAllData?.result?.quests?.docs);
  //   }
  // }, [questAllData]);

  useEffect(() => {
    if (questAllData?.result?.quests?.docs) {
      setData(
        questAllData?.result?.quests?.docs.map((quest) => ({
          ...quest,
          totalTime: quest.mode === "free-to-play" ? 86400000 : quest.totalTime,
        }))
      );
    }
  }, [questAllData]);

  const handleStatusChange = (clickedItem) => {
    let savedStatus = clickedItem.status;
    const newData = Daata.map((item) => {
      if (item._id === clickedItem._id) {
        item.status = item.status === "activate" ? "de-activate" : "activate";
      }
      return item;
    });

    let data = {
      updateQuestData: {
        questId: clickedItem?._id,
        status: savedStatus === "de-activate" ? "activate" : "de-activate",
      },
      currentPage: currentPage,
    };

    dispatch({
      type: types.QuestUpdateRequest,
      payload: data,
    });
    setData(newData);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      render: (item) => (
        <div className="d-flex">
          {" "}
          <FolderIcon /> <div className="questname">{item}</div>{" "}
        </div>
      ),
    },
    {
      title: "Total time",
      dataIndex: "totalTime",
      key: "totalTime",

      render: (item) => (
        <div className="time">{msToTime(item)}</div>
        // <div   >{item}</div>
      ),
    },
    {
      title: "Quantity of tasks ",
      dataIndex: "taskArray",
      key: "taskArray",

      render: (quantityoftasks) => (
        <>
          <div>{quantityoftasks?.length}</div>
        </>
      ),
    },

    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",

      render: (item) => <div>{item}</div>,
    },
    {
      title: "Type of game",
      dataIndex: "mode",
      key: "mode",

      render: (item) => <div className="typeofclue">{item}</div>,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",

      render: (item) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dayjs(item).format("DD-MM-YYYY")}</div>
          <div>{dayjs(item).format("HH:mm")}</div>
        </div>
      ),
    },
    {
      title: "players",
      dataIndex: "participants",
      key: "participants",

      render: (item) => <div>{item?.length}</div>,
    },
    {
      title: "Status",
      dataIndex: "",
      key: "stu",

      // dataIndex: "status",
      // key: "status",

      render: (item) => (
        <div>
          {/* {console.log("itemm here is is", item)} */}
          {item.state === "in-active" && item.participants.length === 0 ? (
            <div
              onClick={(e) => {
                handleStatusChange(item);
              }}
            >
              <Switch checked={item.status === "de-activate" ? false : true} />
            </div>
          ) : (
            <div className="cursor-nodrop">
              <Switch
                checked={item.status === "de-activate" ? false : true}
                disabled
              />
            </div>
          )}
        </div>

        // <div>
        //   {console.log("statu item", item)}
        //   <div
        //     className={`${
        //       item === "activate" ? "typeofclueactive" : "typeofinactive"
        //     }`}
        //   >
        //     {" "}
        //     {item === "activate" ? "Active" : "Inactive"}
        //   </div>
        // </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",

      key: "x",
      render: (item) => (
        <div className="d-flex ">
          {/* {console.log("iteeeeem", item)} */}

          {item.state === "in-active" && item.participants.length === 0 ? (
            <div className="cursor" onClick={() => handlDeleteRecord(item)}>
              <DeleteIcon />
            </div>
          ) : (
            <div className="cursor-nodrop">
              <DeleteIcon />
            </div>
          )}

          {item.state === "in-active" && item.participants.length === 0 ? (
            <div className="ml10 cursor" onClick={() => handleEditRecord(item)}>
              <EditIcon />
            </div>
          ) : (
            // <div className="ml10 cursor-nodrop">
            //   <EditIcon />
            // </div>
            // <div className="ml10 cursor-nodrop">
            //   <EditIcon />
            // </div>
            <div className="ml10 cursor-nodrop">
              <EditIcon />
            </div>
          )}
        </div>
      ),
    },
  ];

  function msToTime(milliseconds) {
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;
    // var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

    return h + ":" + m + ":" + s;
  }
  const handlDeleteRecord = (item) => {
    confirm({
      title: "Delete Quest",
      // icon: <ExclamationCircleFilled />,
      content: "Are you sure to delete this Quest?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        let data = {
          id: item._id,
          currentPage: currentPage,
        };

        dispatch({
          type: types.QuestDeleteRequest,
          payload: data,
          // payload: item._id,
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCloseEditQuestModal = () => {
    window.location.reload();
  };

  const onChangeTaskPagination = (page) => {
    setCurrentPage(page);
    dispatch({
      type: types.QuestListingRequest,
      payload: {
        searchText: searchText ? searchText : searchTextQuest,
        page,
      },
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    dispatch({
      type: types.QuestListingRequest,
      payload: {
        searchText: searchText,
        page: currentPage,
      },
    });
  };

  return (
    <>
      <div className="searchbox">
        <SearchBar onSearch={handleSearch} />
        <span className="questheading">Game Quest</span>
      </div>
      <Table columns={columns} pagination={false} dataSource={Daata} />

      <div className="d-flex justify-content-end mt20">
        <Pagination
          current={currentPage}
          pageSize={10}
          total={questAllData?.result?.quests?.totalDocs}
          onChange={(page) => onChangeTaskPagination(page)}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title={false}
        footer={false}
        open={editQuestModalOpen}
        onCancel={handleCloseEditQuestModal}
        width={1300}
      >
        <EditQuest
          open={editQuestModalOpen}
          editRecordData={questDetailByIdData}
          prizePoolListingAllData={prizePoolListingAllData}
          currentPage={currentPage}
        />
      </Modal>
    </>
  );
};
export default QuestListing;
