import { useEffect, useState } from "react";
import TaskForm from "../../../../SharedComponents/TaskFrom";
const CreateTask = () => {
  const [errorSimilarTitle, setErrorSimilarTitle] = useState(false);
  const [cityAndCountryData, setcityAndCountryData] = useState();
  const [keyIndex, setKeyIndex] = useState(1);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAQPJobaSibKj0ukXQag1aRYn9Kpck9MKw`
      )
        .then((response) => response.json())
        .then((data) => { });
    });
  }, []);

  const getCityCountryVal = (params) => {
    setcityAndCountryData(params);
  };

  useEffect(() => {
    const changeMapPointer = setTimeout(() => {
      setKeyIndex((prev) => prev + 1);
    }, 500);

    return () => clearTimeout(changeMapPointer);
  }, [cityAndCountryData?.latitude, cityAndCountryData?.longitude]);

  return (
    <>
      <div>
        <TaskForm
          cityAndCountryData={cityAndCountryData}
          setcityAndCountryData={setcityAndCountryData}
          errorSimilarTitle={errorSimilarTitle}
          setErrorSimilarTitle={setErrorSimilarTitle}
          getCityCountryVal={getCityCountryVal}
          keyIndex={keyIndex}
        />
      </div>
    </>
  );
};

export default CreateTask;
