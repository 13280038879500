import { useState } from "react";
import { useEffect } from "react";
import TaskForm from "../../../../SharedComponents/TaskFrom";

let createtask = false;
const UpdateTask = ({ editTaskData, currentPage, open }) => {
  const [errorSimilarTitle, setErrorSimilarTitle] = useState(false);
  const [cityAndCountryData, setcityAndCountryData] = useState(
    editTaskData?.location
  );

  const [keyIndex, setKeyIndex] = useState(1);

  const getCityCountryVal = (params) => {
    setcityAndCountryData(params);
  };

  useEffect(() => {
    const changeMapPointer = setTimeout(() => {
      setKeyIndex((prev) => prev + 1);
    }, 500);

    return () => clearTimeout(changeMapPointer);
  }, [cityAndCountryData?.latitude, cityAndCountryData?.longitude]);

  useEffect(() => {
    if (errorSimilarTitle) {
      createtask = true;
    }
  }, [errorSimilarTitle]);
  return (
    <div>
      <TaskForm
        cityAndCountryData={cityAndCountryData}
        setcityAndCountryData={setcityAndCountryData}
        editTaskData={editTaskData}
        createtask={createtask}
        currentPage={currentPage}
        errorSimilarTitle={errorSimilarTitle}
        setErrorSimilarTitle={setErrorSimilarTitle}
        getCityCountryVal={getCityCountryVal}
        keyIndex={keyIndex}
      />
    </div>
  );
};

export default UpdateTask;
